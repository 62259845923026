<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <FormInputTextWidget
        class="col-md-6 col-lg-3"
        label="Nome"
        v-model="user.name"
        :errorMsg="errorHash.name"
        @input="removeFieldError('name')"
      />

      <FormInputTextWidget
        class="col-md-6 col-lg-3"
        label="Cognome"
        v-model="user.surname"
        :errorMsg="errorHash.surname"
        @input="removeFieldError('surname')"
      />
      <FormInputTextWidget
        class="col-md-6 col-lg-3"
        label="Email"
        v-model="user.email"
        type="email"
        :disabled="edit"
        :errorMsg="errorHash.email"
        @input="removeFieldError('email')"
      />

      <FormInputTextWidget
        v-if="!edit"
        class="col-md-6 col-lg-3"
        label="Password"
        v-model="user.password"
        type="password"
        :errorMsg="errorHash.password"
        @input="removeFieldError('password')"
      />

      <div class="col-12 mt-3">
        <button class="btn btn-primary px-5">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import { emailIsValid } from '../../../helpers/validation';

import validateMixin from '../../../libs/Form/mixins/validate';
import modalsManagerMixin from '../../../libs/Modals/mixins/manager';
import rolesMixin from '../../../mixins/roles';

export default {
  name: 'user-form',
  mixins: [validateMixin, modalsManagerMixin, rolesMixin],
  components: {
    FormContainer: () => import('../../components/Form/FormContainer'),
    FormInputTextWidget: () => import('../../../libs/Form/components/Inputs/Widgets/FormInputTextWidget'),
    FeedbackAlert: () => import('../../../libs/Feedback/components/Alert'),
  },
  props: {
    user: {
      type: Object,
      default: () => ({
        company: null,
        name: null,
        surname: null,
        email: null,
        password: null,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.user.name) {
        this.addError('Inserisci il nome', 'name');
      }
      if (!this.user.surname) {
        this.addError('Inserisci il cognome', 'surname');
      }

      if (!this.edit) {
        if (!this.user.email) {
          this.addError('Inserire l\'email.', 'email');
        } else if (!emailIsValid(this.user.email)) {
          this.addError('Email non valida.', 'email');
        }
      }

      return !this.hasErrors;
    },
    submit () {
      if (!this.isValid()) {
        return;
      }

      const userToReturn = { ...this.user };

      this.$emit('submitForm', userToReturn);
    },
  },
};

</script>
